const materialsLang = {
  messages: {
    tw: {
      'material_management': '教材管理',
      'add_new_material': '新增教材',
    },
    en: {
      'material_management': 'Material Management',
      'add_new_material': 'Add New Material',
    },
  },
};

export {
  materialsLang
};
