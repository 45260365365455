export default [
  {
    name: '__slot:title',
    title: 'material',
    sortField: 'title',
    visible: true,
  },
  {
    name: '__slot:type',
    title: 'material_type',
    visible: true,
  },
  {
    name: '__slot:MaterialCategory',
    title: 'material_category',
    visible: true,
  },
  {
    name: '__slot:MaterialTheme',
    title: 'material_theme',
    visible: true,
  },
  {
    name: '__slot:MaterialLevel',
    title: 'material_level',
    sortField: 'level',
    visible: true,
  },
  {
    name: '__slot:Operations',
    title: 'operations',
    visible: true,
  },
];
