<script>
export default {
  data() {
    return {
      types: [
        { value: 17, text: this.$t('normal') },
        { value: 18, text: this.$t('for_kid') },
        { value: 19, text: this.$t('from_vip') },
        { value: 35, text: this.$t('academy') },
        { value: 36, text: this.$t('not_kid') },
      ],
      level: [
        { value: '1', text: 'Level 1' },
        { value: '2', text: 'Level 2' },
        { value: '3', text: 'Level 3' },
        { value: '4', text: 'Level 4' },
        { value: '5', text: 'Level 5' },
        { value: '6', text: 'Level 6' },
        { value: '7', text: 'Level 7' },
        { value: '8', text: 'Level 8' },
        { value: '9', text: 'Level 9' },
        { value: '10', text: 'Level 10' },
        { value: '11', text: 'Level 11' },
        { value: '12', text: 'Level 12' },
      ],
      category: [
        { value: 1, text: this.$t('vocabulary') },
        { value: 2, text: this.$t('reading') },
        { value: 3, text: this.$t('speaking') },
        { value: 4, text: this.$t('grammar') },
        { value: 5, text: this.$t('pronunciation') },
        { value: 6, text: this.$t('listening') },
      ],
      theme: [
        { value: 1, text: this.$t('business') },
        { value: 2, text: this.$t('culture') },
        { value: 3, text: this.$t('daily_life') },
        { value: 4, text: this.$t('entertainment') },
        // {value: 5, text:this.$t('fashion')},
        // {value: 6, text:this.$t('finance')},
        { value: 7, text: this.$t('foundation') },
        // {value: 8, text:this.$t('movieMusic')},
        { value: 9, text: this.$t('news') },
        // {value: 10, text:this.$t('sports')},
        { value: 11, text: this.$t('technology') },
        { value: 12, text: this.$t('travel') },
        // {value: 13, text:this.$t('voiceTube')},
        // {value: 14, text:this.$t('GEPT')},
        { value: 15, text: this.$t('TOEFL') },
        { value: 16, text: this.$t('TOEIC_reading') },
        { value: 25, text: this.$t('TOEIC_listening') },
        { value: 17, text: this.$t('IELTS') },
        { value: 18, text: this.$t('food_restaurant') },
        { value: 20, text: this.$t('health') },
      ],
    };
  },
};
</script>
