<template lang="pug">
ul.pagination.m-0
  li(:class="{'disabled': isOnFirstPage}")
    a(@click="loadPage(1)")
      span &laquo;
  li(:class="{'disabled': isOnFirstPage}")
    a(href="#" @click.prevent="loadPage('prev')")
      span &nbsp;&lsaquo;
  template(v-if="notEnoughPages")
    li(
      v-for="(n, index) in totalPage" :key="index"
      :class="{'active': isCurrentPage(n)}"
    )
      a(@click.prevent="loadPage(n)" v-html="n" href="#")
  template(v-else)
    li(
      v-for="(n, index) in windowSize" :key="index"
      :class="{'active': isCurrentPage(windowStart+n-1)}"
    )
      a(@click.prevent="loadPage(windowStart+n-1)" v-html="windowStart+n-1" href="#")
  li(:class="{'disabled': isOnLastPage}")
    a(href="#" @click.prevent="loadPage('next')")
      span &rsaquo;&nbsp;
  li(:class="{'disabled': isOnLastPage}")
    a(@click="loadPage(totalPage)")
      span &raquo;
</template>

<script>
import VuetablePaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin.vue';

export default {
  mixins: [VuetablePaginationMixin],
};
</script>
