export default {
  messages: {
    en: {
      add: 'Add',
      edit: 'Edit',
      material: 'Material',
      start_time: 'Start Time',
      end_time: 'End Time',
      self_material: 'Your Materials',
      own_material_mes: 'Search your own materials',
      title: 'Title',
      type: 'Type',
      category: 'Category',
      theme: 'Theme',
      level: 'Level',
      normal: 'Normal',
      for_kid: 'For Kid',
      from_vip: 'From Vip',
      academy: 'Academy',
      not_kid: 'Not Kid',
      go: 'Go',
      reset: 'Reset',
    },
    tw: {
      add: '新增',
      edit: '編輯',
      material: '教材',
      start_time: '開始時間',
      end_time: '結束時間',
      self_material: '你的教材',
      own_material_mes: '搜尋你製作的教材',
      title: '標題',
      type: '類型',
      category: '種類',
      theme: '主題',
      level: '等級',
      normal: '一般',
      for_kid: '小孩',
      from_vip: '學員上傳',
      academy: '百家書苑',
      not_kid: '大人',
      go: '搜尋',
      reset: '重設',
    },
  },
};
