var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel panel-default"
  }, [_c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "book"
    }
  }), _vm._v(_vm._s(_vm.$t('material_management')))], 1)]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('div', {
    staticClass: "panel panel-default"
  }, [_c('div', [_c('div', [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.edit_material_modal",
      modifiers: {
        "edit_material_modal": true
      }
    }],
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addMaterial
    }
  }, [_vm._v(_vm._s(_vm.$t('add_new_material')))]), _c('material-filter', {
    on: {
      "search": _vm.search,
      "reset": _vm.reset
    }
  })], 1)]), _c('br'), _c('div', {
    staticClass: "panel panel-default"
  }, [_c('div', [_c('div', [_c('material-table', {
    on: {
      "editMaterial": _vm.editMaterial
    }
  })], 1)])])])])]), _c('edit-material-modal', {
    attrs: {
      "action": _vm.action
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }