<template lang="pug">
  b-modal#edit_material_modal(centered :title="$t(action)+$t('material')" header-bg-variant="primary" header-text-variant="light"  :backdrop="false" no-close-on-backdrop)
    .text-center
      .col-xs-12
        .col-xs-12
          input#loadFileXml.btn.btn-primary(type='button' :value="$t('upload')")
          input#material_edit_file(type='file' style='display:none;' name='material_file' accept='.doc,.docx,.pdf,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document')
          div {{ this.file }}
          div {{ material.title }}
        div(slot='modal-header')
          h2(style='color:blue;text-align:center') {{ $t(action) + $t('material') }}
        label.col-xs-3.mt-2 {{ $t('material')+$t('name') }}
        .col-xs-9
          b-form-input.col-xs-12(v-model='material.title' :label="$t('material')+$t('name')")
        label.col-xs-3.mt-3 {{ $t('material')+$t('type') }}
        .col-xs-9
          b-form-select(v-model='material.type' :options='types')
        label.col-xs-3.mt-3 {{ $t('select')+$t('level') }}
        .col-xs-9
          b-form-select(v-model='material.level' :options='level')
        label.col-xs-3.mt-3 {{ $t('select')+$t('category') }}
        .col-xs-9
          b-form-select(v-model='material.category' :options='category' options-value='val' :placeholder="$t('search_category_here')" multiple style='width:100%')
        label.col-xs-3.mt-3 {{ $t('select')+$t('theme') }}
        .col-xs-9
          b-form-select(v-model='material.theme' :options='theme' options-value='val' :placeholder="$t('search_theme_here')" multiple style='width:100%')
</template>

<script type="text/javascript">
import materialMixin from './../mixin/materialMixin.vue';
import i18n from './../lang';

export default {
  name: 'EditMaterialModal',

  mixins: [materialMixin],

  props: {
    action: {
      type: String,
      default: function() {
        return 'add';
      },
    },

    material: {
      type: Object,
      default: function() {
        return {
          title: 'Material Title',
          type: null,
          level: null,
          category: [],
          theme: [],
        };
      },
    },
  },

  i18n,

  data() {
    return {
      file: '',
    };
  },

  created(){
  },

  methods: {},
};
</script>
