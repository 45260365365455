<template lang="pug">
  div
    vuetable(
      ref="table"
      :reactive-api-url="false"
      :api-mode="false"
      data-path="data"
      pagination-path=""
      :load-on-start="false"
      :css="css.table"
      :fields="fields"
      @vuetable:pagination-data="onPaginationData"
    )
      template(slot='title' slot-scope='{ rowData }')
        div(style='width: 300px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;' :title='rowData.title')
          div
            a(:href='rowData.file') {{ rowData.title }}
      template(slot='type' slot-scope='{ rowData }')
        | {{ $t(getMaterialType(rowData.type)) }}
      template(slot='MaterialCategory' slot-scope='{ rowData }')
        template(v-for='(want, index) in rowData.learning_wants')
          div {{ want.content.title }}
      template(slot='MaterialTheme' slot-scope='{ rowData }')
        template(v-for='(topic, index) in rowData.interested_topics')
          div {{ topic.content.title }}
      template(slot='MaterialLevel' slot-scope='{ rowData }')
        div {{ rowData.level }}
      template(slot='Operations' slot-scope='{ rowData }')
        button.btn.btn-primary(v-b-modal.edit_material_modal @click="editMaterial") {{ $t('edit') }}

    .d-flex.justify-content-between.align-items-center
      vuetable-pagination-info(
        ref="paginationInfo"
        :info-template="$t('pagination_info')"
        :no-data-template="$t('no_relevant_data')"
      )
      bootstrap-pagination(
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      )
</template>

<script>
import Vuetable from 'vuetable-2';
import BootstrapPagination from '@/components/vueTable/vuetablePaginationBootstrap/index.vue';
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue';
import VuetableCss from '@/components/vueTable/vuetableCss';
import fields from './fields';
import i18n from './lang';
import tableData from './tableData';

export default {
  components: {
    Vuetable,
    BootstrapPagination,
    VuetablePaginationInfo,
  },

  i18n,

  data: () => ({
    css: VuetableCss,
    tableData: tableData,
  }),
  computed: {
    fields() {
      return fields.map((field) => ({
        name: field.name,
        title: this.$t(field.title),
        sortField: field.sortField,
        visible: field.visible,
      }));
    },
  },
  mounted() {
    this.setTableData();
  },
  methods: {
    editMaterial(){
      this.$emit('editMaterial', 'edit');
    },
    setTableData() {
      this.$refs['table'].setData(this.tableData);
    },
    getMaterialType(data) {
      if (data === 17) {
        return 'normal';
      } else if (data === 18) {
        return 'forkid';
      } else if (data === 35) {
        return 'academy';
      } else {
        return '';
      }
    },
    onPaginationData(paginationData) {
      this.$refs['pagination']['setPaginationData'](paginationData);
      this.$refs['paginationInfo']['setPaginationData'](paginationData);
    },
    onChangePage(page) {
      this.$refs['table']['changePage'](page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/base/base';
@import '@/styles/base/extension';
</style>
