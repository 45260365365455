<template lang="pug">
  .panel.panel-default(v-cloak='')
    .wuwow-card
      .wuwow-card-head
        h4.wuwow-card-title
          font-awesome-icon(icon="book").mr-3
          | {{$t('material_management') }}
      .wuwow-card-body
        .panel.panel-default
          div
            div
              button.btn.btn-primary(type='button' v-b-modal.edit_material_modal @click="addMaterial") {{ $t('add_new_material') }}
              material-filter(@search="search" @reset="reset")
          br
          .panel.panel-default
            div
              div
                material-table(@editMaterial="editMaterial" )
    edit-material-modal(:action="action")
</template>

<script type="text/javascript">
import { materialsLang } from './lang';
import MaterialFilter from '@/components/consultant/material/filter';
import MaterialTable from '@/components/consultant/material/materialTable';
import EditMaterialModal from '@/components/consultant/material/editMaterialModal';

export default {
  name: 'Materials',

  components: {
    MaterialFilter,
    MaterialTable,
    EditMaterialModal,
  },

  i18n: materialsLang,

  data() {
    return {
      action: 'edit',
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    search(query){
      console.log(query);
    },

    reset(data){
      console.log(data);
    },

    addMaterial(){
      this.action = 'add';
    },

    editMaterial(data){
      this.action = data;
    },
  },
};
</script>

<style lang="scss"></style>
