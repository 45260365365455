<template lang="pug">
  div
    .row.mt-3
      .col-md-3
        label {{ $t('start_time') }}
        date-picker.w-100.d-block(v-model="query.start_time" format="YYYY-MM-DD" type="date")
      .col-md-3
        label {{ $t('end_time') }}
        date-picker.w-100.d-block(v-model="query.end_time" format="YYYY-MM-DD" type="date")
      .col-md-3
        label {{ $t('self_material') }}
        b-form-checkbox#filterSelf(v-model="query.consultant" value='true' unchecked-value='false') {{ $t('own_material_mes') }}
      .col-md-3
        label {{ $t('title') }}
        b-form-input.form-control(v-model="query.title")
    .row.mt-3
      .col-md-3
        label {{ $t('type') }}
        b-form-select(v-model="query.type" :options='types' options-value='val' :placeholder="$t('can_multi_choose')" style='width:100%')
      .col-md-3
        label {{ $t('category') }}
        b-form-select(v-model="query.category" :options='category' options-value='val' :placeholder="$t('can_multi_choose')" multiple style='width:100%')
      .col-md-3
        label {{ $t('theme') }}
        b-form-select(v-model="query.theme" :options='theme' options-value='val' :placeholder="$t('can_multi_choose')" multiple style='width:100%')
      .col-md-3
        label {{ $t('level') }}
        b-form-select(v-model="query.level" :options='level' options-value='val' :placeholder="$t('can_multi_choose')" multiple style='width:100%')
      .col-md-3
        label
        .input-group
          button.btn.btn-primary(@click='search') {{ $t('go') }}
          button.btn.btn-default.ml-3(@click='reset') {{ $t('reset') }}
</template>

<script type="text/javascript">
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import i18n from './../lang';
import materialMixin from './../mixin/materialMixin.vue';

export default {
  name: 'MaterialFilter',

  components: {
    DatePicker,
  },

  mixins: [materialMixin],

  i18n,

  data() {
    return {
      query: {
        start_time: null,
        end_time: null,
        consultant: null,
        title: null,
        type: null,
        category: [],
        theme: [],
        level: [],
      },

    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    search() {
      this.$emit('search', this.query);
    },
    reset() {
      this.query = {
        start_time: null,
        end_time: null,
        consultant: null,
        title: null,
        type: null,
        category: [],
        theme: [],
        level: [],
      };
      this.$emit('reset', '555');
    },
  },
};
</script>

<style lang="scss"></style>
