export default {
  messages: {
    en: {
      material: 'Material',
      material_type: 'Material Type',
      material_category: 'Material Category',
      material_theme: 'Material Theme',
      material_level: 'Material Level',
      operations: 'Operations',
      pagination_info: 'Displaying {from} to {to} of {total} items',
    },
    tw: {
      material: '教材',
      material_type: '教材類型',
      material_category: '教材種類',
      material_theme: '教材主題',
      material_level: '教材等級',
      operations: '操作',
      pagination_info: '正在顯示第 {from} 項到第 {to} 項，共 {total} 項',
    },
  },
};
