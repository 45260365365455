/* eslint-disable */
export default {
  'current_page': 1,
  'data': [
    {
      'id': 119392,
      'author_user_id': 7911,
      'approve_user_id': 40559,
      'approved_at': '2016-10-30 06:02:34',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Chocolate and Wine A New Weight Loss Approach*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lv8 - food, health, news - Chocolate and Wine A New Weight Loss Approach.pptx',
      'created_at': '2016-10-30 06:02:34',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 7911,
        'account': 'HBT_Sophia',
        'english_name': 'Sophia Chang',
        'chinese_name': '張涵茹'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 119392,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 119392,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 119392,
          'it_id': 18,
          'content': {
            'id': 18,
            'title': '食物/餐廳 Food / Restaurant',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Food.jpg'
          }
        },
        {
          'material_id': 119392,
          'it_id': 20,
          'content': {
            'id': 20,
            'title': '健康 Health',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Health.jpg'
          }
        }
      ]
    },
    {
      'id': 123692,
      'author_user_id': 6635,
      'approve_user_id': 40559,
      'approved_at': '2016-11-09 04:57:47',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Homes on Mars (1)*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lvl 8 - news, technology - Homes on Mars (1) Mars Showhome.pptx',
      'created_at': '2016-11-09 04:57:47',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 6635,
        'account': 'HBT_Michael Ho',
        'english_name': 'Michael Ho',
        'chinese_name': '何季鴻'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 123692,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 123692,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 123692,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        }
      ]
    },
    {
      'id': 123693,
      'author_user_id': 6635,
      'approve_user_id': 40559,
      'approved_at': '2016-11-09 04:58:31',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Homes on Mars (2)* ',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lvl 8 - news, technology - Homes on Mars (2) The Red Planet.pptx',
      'created_at': '2016-11-09 04:58:31',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 6635,
        'account': 'HBT_Michael Ho',
        'english_name': 'Michael Ho',
        'chinese_name': '何季鴻'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 123693,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 123693,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 123693,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        }
      ]
    },
    {
      'id': 121338,
      'author_user_id': 7911,
      'approve_user_id': 40559,
      'approved_at': '2016-11-03 22:00:50',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Hour for Success*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading lv.8 - business, daily life, news - Hour for Success.pptx',
      'created_at': '2016-11-03 22:00:50',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 7911,
        'account': 'HBT_Sophia',
        'english_name': 'Sophia Chang',
        'chinese_name': '張涵茹'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 121338,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 121338,
          'it_id': 1,
          'content': {
            'id': 1,
            'title': '商業 Business',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Business-and-Finance.jpg'
          }
        },
        {
          'material_id': 121338,
          'it_id': 3,
          'content': {
            'id': 3,
            'title': '日常生活 Daily Life',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Daily-Life-and-Recreation.jpg'
          }
        },
        {
          'material_id': 121338,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        }
      ]
    },
    {
      'id': 227005,
      'author_user_id': 6735,
      'approve_user_id': 40559,
      'approved_at': '2017-05-10 00:25:20',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Reaction to Robots*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lv.8 - Daily life, News, Technology - Reaction to Robots.pptx',
      'created_at': '2017-05-10 00:25:20',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 6735,
        'account': 'HBF_Stefan',
        'english_name': 'Stefan Costilow',
        'chinese_name': '漢堡哥'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 227005,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 227005,
          'it_id': 3,
          'content': {
            'id': 3,
            'title': '日常生活 Daily Life',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Daily-Life-and-Recreation.jpg'
          }
        },
        {
          'material_id': 227005,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 227005,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        }
      ]
    },
    {
      'id': 115945,
      'author_user_id': 443,
      'approve_user_id': 40559,
      'approved_at': '2016-10-21 22:53:16',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Sexy Grandad*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Readidng - lv8 - culture, fashion, entertainment, news - Sexy Grandad.pptx',
      'created_at': '2016-10-21 22:53:16',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 443,
        'account': 'HBF_DavidF',
        'english_name': 'David Flint',
        'chinese_name': 'unfilled'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 115945,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 115945,
          'it_id': 2,
          'content': {
            'id': 2,
            'title': '文化 Culture',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Culture.jpg'
          }
        },
        {
          'material_id': 115945,
          'it_id': 4,
          'content': {
            'id': 4,
            'title': '娛樂 Entertainment',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Enteraiment.jpg'
          }
        },
        {
          'material_id': 115945,
          'it_id': 5,
          'content': {
            'id': 5,
            'title': '時尚 Fashion',
            'type': 'normal',
            'order': 0,
            'weight': 0,
            'image': '/vip/interested_topics_img/Daily-Life-and-Recreation.jpg'
          }
        },
        {
          'material_id': 115945,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        }
      ]
    },
    {
      'id': 116456,
      'author_user_id': 443,
      'approve_user_id': 40559,
      'approved_at': '2016-10-23 04:24:41',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' SIM Tracking*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lv8 - daily life,culture, news - SIM Tracking.pptx',
      'created_at': '2016-10-23 04:24:41',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 443,
        'account': 'HBF_DavidF',
        'english_name': 'David Flint',
        'chinese_name': 'unfilled'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 116456,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 116456,
          'it_id': 2,
          'content': {
            'id': 2,
            'title': '文化 Culture',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Culture.jpg'
          }
        },
        {
          'material_id': 116456,
          'it_id': 3,
          'content': {
            'id': 3,
            'title': '日常生活 Daily Life',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Daily-Life-and-Recreation.jpg'
          }
        },
        {
          'material_id': 116456,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        }
      ]
    },
    {
      'id': 123277,
      'author_user_id': 6635,
      'approve_user_id': 40559,
      'approved_at': '2016-11-08 05:45:05',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Stop Junk Food Ads on Kids‘ Apps (1)*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lvl 8 - news, technology, food, health - Stop Junk Food Ads on Kids‘ Apps (1) Childhood Obesity.pptx',
      'created_at': '2016-11-08 05:45:05',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 6635,
        'account': 'HBT_Michael Ho',
        'english_name': 'Michael Ho',
        'chinese_name': '何季鴻'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 123277,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 123277,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 123277,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        },
        {
          'material_id': 123277,
          'it_id': 18,
          'content': {
            'id': 18,
            'title': '食物/餐廳 Food / Restaurant',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Food.jpg'
          }
        },
        {
          'material_id': 123277,
          'it_id': 20,
          'content': {
            'id': 20,
            'title': '健康 Health',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Health.jpg'
          }
        }
      ]
    },
    {
      'id': 123278,
      'author_user_id': 6635,
      'approve_user_id': 40559,
      'approved_at': '2016-11-08 05:46:30',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Stop Junk Food Ads on Kids‘ Apps (2)*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lvl 8 - news, technology, food, health - Stop Junk Food Ads on Kids‘ Apps (2) Digital Marketing.pptx',
      'created_at': '2016-11-08 05:46:30',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 6635,
        'account': 'HBT_Michael Ho',
        'english_name': 'Michael Ho',
        'chinese_name': '何季鴻'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 123278,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 123278,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 123278,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        },
        {
          'material_id': 123278,
          'it_id': 18,
          'content': {
            'id': 18,
            'title': '食物/餐廳 Food / Restaurant',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Food.jpg'
          }
        },
        {
          'material_id': 123278,
          'it_id': 20,
          'content': {
            'id': 20,
            'title': '健康 Health',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Health.jpg'
          }
        }
      ]
    },
    {
      'id': 128886,
      'author_user_id': 967,
      'approve_user_id': 40559,
      'approved_at': '2016-11-20 21:35:48',
      'disapprove_user_id': null,
      'disapprove_at': null,
      'title': ' Travel With Google Earth in VR*',
      'level': 8,
      'status': 1,
      'type': 17,
      'sort': 0,
      'file': 'https://cdn2.wuwow.tw/material/Reading - lvl 8- Technology, News -- Travel With Google Earth in VR_0.pptx',
      'created_at': '2016-11-20 21:35:48',
      'updated_at': '2019-05-08 11:19:03',
      'author': {
        'id': 967,
        'account': 'backup_HBT_Mary',
        'english_name': 'Mary Ho',
        'chinese_name': '何玟礽'
      },
      'author_admin': null,
      'approve': null,
      'disapprove': null,
      'learning_wants': [
        {
          'material_id': 128886,
          'lw_id': 2,
          'content': {
            'id': 2,
            'title': '閱讀 Reading',
            'order': 0,
            'weight': 0,
            'image': null
          }
        }
      ],
      'interested_topics': [
        {
          'material_id': 128886,
          'it_id': 9,
          'content': {
            'id': 9,
            'title': '新聞 News',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/News-and-Hot-Topics.jpg'
          }
        },
        {
          'material_id': 128886,
          'it_id': 11,
          'content': {
            'id': 11,
            'title': '科技 Technology',
            'type': 'normal',
            'order': 1,
            'weight': 0,
            'image': '/vip/interested_topics_img/Technology.jpg'
          }
        }
      ]
    }
  ],
  'from': 1,
  'last_page': 1127,
  'next_page_url': 'https://taichi.wuwow.tw/api/v2/admin/materials?page=2',
  'path': 'https://taichi.wuwow.tw/api/v2/admin/materials',
  'per_page': 10,
  'prev_page_url': null,
  'to': 10,
  'total': 11267
}
